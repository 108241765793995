import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
interface LinkProps {
  children: ReactNode;
  href: string;
}

const LinkWrapper = styled.a`
text-decoration: none;
margin: 0px 15px;
`;

const Link: FC<LinkProps> = ({ children, href }) => {
  return (
    <LinkWrapper href={href}>
      {children}
    </LinkWrapper >
  );
};

export default Link;
