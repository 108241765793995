import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from 'rebass';
import Container from "../../components/elements/Container";
import { H2, H3, Bold, H5, Title } from "../../components/elements";
import { theme } from "../../theme";
import BgVector from "../../images/vtr.webp";
import Image from "../../components/elements/Image";
import { down } from 'styled-breakpoints';
import Heading from "../../components/elements/Heading";
import { api } from "../../utils/api";
import { AboutData } from "../../types/about";
import { useDispatch, useSelector } from '../../store';
import { getCharacterFirst, getCharacterSecond, getHomeImage } from "../../utils/common";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import parse from 'html-react-parser';
import Link from "../../components/elements/Link";
import StyledRichText from "../../components/elements/StyledRichText";
interface AboutProps {
  phoneNumber?: string;
}
const AboutSection = styled.section`
  text-align: center;
  padding: ${props => props.theme.spacing[4]}rem 0;
`;

const AboutContent = styled.div`
font-weight: 300;
font-size: .9rem;
line-height: 30px !important;
margin-top: 15px;
color: ${(props: any) => (props.theme.colors.mediumGray)};
text-align: left;
}
  `;
const BookWrapper = styled.div`
    background: ${(props: any) => (props.theme.colors.brown)};
    padding: 25px;
    max-width: 80%;
    margin-left: auto;
    margin-top: -84px;
    position: relative;
    ::after {
      position: absolute;
    content: "";
    height: 50px;
    width: 50px;
    right: 15px;
    bottom: 15px;
    z-index: 0;
    background-image: url(${BgVector});;
    }
    ${down('md')}{
      padding-right: 45px;
      max-width:100%;
      }
    }
  `;
const About: FC<AboutProps> = () => {
  const dispatch = useDispatch();
  const [aboutRecord, setAboutRecord] = useState<AboutData>();
  const { records, } = useSelector(state => state.about);
  const [contactPhone, setContactPhone] = React.useState<string>();

  const { records: globalData } = useSelector(state => state.global);
  const globalDataId = globalData?.data?.id;
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
  async function fetchData() {
    const msg = `An error while retrieving about content!`;
    try {
      const params = {
        "populate": "blocks,media,media.file"
      };
      // dispatch(getAll(params));
      const { status, data } = await api("/api/about", "get", params);
      if (status >= 200 && status <= 299) {
        setAboutRecord(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalDataId !== '')
      setContactPhone(globalData?.data?.attributes?.contact?.phone);
  }, [globalDataId]);
  const aboutImageData = getHomeImage(aboutRecord?.data?.attributes?.media);
  const imageSrc = aboutImageData && aboutImageData[0]?.src;
  const alternativeText = aboutImageData && aboutImageData[0]?.alt;
  const desc = aboutRecord?.data?.attributes?.desc ?? '';
  return (
    <AboutSection id="home">
      <Container>
        <Flex flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']} justifyContent='center' >
          <Box m={[2, 3]}
            sx={{
              width: '45%',
              '@media screen and (max-width: 64em)': {
                width: '100%',
              }
            }} >
            <Title textColor={theme.colors.blue}>{"About Us"}</Title>
            <Heading fontSize={'2rem'} lineHeight={1} textFontWeight={theme.fontWeight.light} boldFontWeight={theme.fontWeight.bold} textAlignMob={'left'} textAlign={'left'} textColor={theme.colors.lightBlack} text={getCharacterFirst(aboutRecord?.data?.attributes?.title)} boldText={getCharacterSecond(aboutRecord?.data?.attributes?.title)} boldTextColor={theme.colors.blue} />
            <StyledRichText>
              <AboutContent >
                {parse(desc)}
              </AboutContent>
            </StyledRichText>
            <Flex alignItems='center' my={3}
              sx={{
                '@media screen and (max-width: 64em)': {
                  justifyContent: "center",
                }
              }} >

              <Image
                src={"temp.svg"}
                alt={"company mission icon"}
              />
              <Title lineHeight={1} textColor={theme.colors.lightBlack} fontWeight={theme.fontWeight.semibold}>Our company mission -</Title>
            </Flex>
            <H3 lineHeight={1.4} textAlignMob={'center'}><Bold textColor="#077CC3" style={{ fontStyle: "italic", fontSize: "30px" }}>“Bringing Imagination to Life"</Bold> </H3>
          </Box>
          <Box m={[2, 3]}
            sx={{
              width: '55%',
              '@media screen and (max-width: 64em)': {
                width: '100%',
                margin: '0',
              }
            }}>
            <Image
              src={imageSrc ?? ''}
              alt={alternativeText ?? ''}
              apiImageUrl={true}
              style={{ width: '100%', maxWidth: '100%', minHeight: '550px', objectFit: 'cover' }}
            />
            <BookWrapper>
              <H2 lineHeight={1.2} textColor={theme.colors.white} fontWeight={theme.fontWeight.light}>We provide
                <Bold textColor={theme.colors.white}  > professional pool</Bold> design services
              </H2>
              <H3 textColor={theme.colors.white} margin="12px 0" white-space="nowrap" fontWeight={theme.fontWeight.semibold}> BOOK A CONSULTATION NOW ! </H3>

              {/* <H2 textColor={theme.colors.white} >{globalRecord?.data?.attributes?.contact?.phone} </H2> */}
              <Link href={`tel:${contactPhone}`}>
                <H2 textColor={theme.colors.white} fontWeight={theme.fontWeight.semibold}>{contactPhone} </H2>
              </Link>

            </BookWrapper>

          </Box>
        </Flex>
      </Container>
    </AboutSection>
  );
};

export default About;